@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@dpdhl/angular-shared-ui/styles/tailwind';
@import '@dpdhl/angular-shared-ui/styles/fonts';
@import '@dpdhl/angular-shared-ui/styles/themes/dpdhl';

.allgood {
  color: #4caf50;
}

.warning {
  color: #fc0;
}

.critical {
  color: #eb131e;
}

.noconnection {
  color: #003d7c;
}
